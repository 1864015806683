import { Component, inject } from '@angular/core';
import { BNContactService } from '../bncontact.service';
import { FormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { SpinloaderComponent } from '../spinloader.component';

@Component({
  selector: 'bn-newsletter-subscribe-form',
  standalone: true,
  imports: [
    FormsModule,
    AsyncPipe,
    SpinloaderComponent
  ],
  template: `
    <ng-content></ng-content>
    @let contactSend = this.contactSendSuccessfully$ | async;
    @if (contactSend === null) {
      <form>
        <input type="text" id="name" name="name" placeholder="Hogyan szólíthatunk?" (focus)="nameFillError=false" [class.error]="nameFillError" [(ngModel)]="name" required>
        <input type="email" id="email" name="email" (focus)="emailFillError = false" [class.error]="emailFillError" placeholder="E-mail címed" [(ngModel)]="email" required>
        <button class="button primary" (click)="sendContact()" [disabled]="clicked">
          Feliratkozom
          @if (clicked) {
            <bn-spinloader class="no-bg small inline"></bn-spinloader>
          }
        </button>
      </form>
    } @else if (contactSend === false) {
      <h3 class="error">Ajjaj, valami gond van nálunk és nem sikerült feliratkozni :( </h3>
    } @else {
      <h3 class="success">Hamarosan küldünk egy megerősítő e-mailt!</h3>
    }
  `,
  styles: `
    .error {
      animation: tilt-n-move-shaking 0.1s linear;
      animation-iteration-count: 2;
      border-color: red;
    }
    @keyframes tilt-n-move-shaking {
      0% { transform: translate(0, 0) rotate(0deg); }
      25% { transform: translate(5px, 5px) rotate(5deg); }
      50% { transform: translate(0, 0) rotate(0deg); }
      75% { transform: translate(-5px, 5px) rotate(-5deg); }
      100% { transform: translate(0, 0) rotate(0deg); }
    }
  `
})
export class NewsletterSubscribeFormComponent {
  email = '';
  name = '';
  clicked = false;
  emailFillError = false;
  nameFillError = false;

  private contactService = inject(BNContactService);
  contactSendSuccessfully$: Observable<null | boolean> = of(null);

  sendContact() {
    this.emailFillError = !this.email || !this.email.match(/^\S+@\S+\.\S+$/);
    this.nameFillError = !this.name;
    if (this.emailFillError || this.nameFillError) {
      return;
    }

    this.clicked = true;

    this.contactSendSuccessfully$ = this.contactService.subscribeToNewsletter(this.name, this.email);
    this.email = '';
    this.name = '';
  }
}
